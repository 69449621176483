/**
 *	Table of Content
 *
 *  # Sidebar
 *  # Main
 *	# Header
 *  # Content
 */

/**
 *  # Sidebar
 */
.site-sidebar {
  position: relative;
  width: 5rem;
  padding: 25px 0 20px;
  box-shadow: 0 2.5px 5px 0 rgba(0, 0, 0, 0.16);
  transition: all 0.3s;

  background: rgb(12,123,169);
  background: -moz-radial-gradient(circle, rgba(12,123,169,1) 0%, rgba(16,176,200,1) 100%);
  background: -webkit-radial-gradient(circle, rgba(12,123,169,1) 0%, rgba(16,176,200,1) 100%);
  background: radial-gradient(circle, rgba(12,123,169,1) 0%, rgba(16,176,200,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0c7ba9",endColorstr="#10b0c8",GradientType=1);
}
.site-sidebar.open {
  width: 300px;
  padding: 25px 0;
}
.site-sidebar .site-brand img {
  width: auto;
  height: 50px;
  transition: all 0.3s;
}
.site-sidebar.open .site-brand img {
  width: auto;
  height: 100px;
}
.site-brand {
  text-align: center;
  // padding: 10px;
  margin-bottom: 16px;
}
.site-brand img {
  width: auto;
  height: 100px ;
}
.site-navigation {
  height: calc(100vh - 3.125rem - 4.375rem - 4rem);
  overflow-x: hidden;
  // overflow-y: scroll;
}
.site-navigation::-webkit-scrollbar {
  width: 5px;
}
.site-navigation::-webkit-scrollbar-track {
  background: transparent;
}
.site-navigation::-webkit-scrollbar-thumb {
  border-radius: 6.25rem;
  background: rgb(12,123,169);
}
.site-navigation::-webkit-scrollbar-thumb:hover {
  background: rgb(12,123,169);
}
.site-navigation .nav-item {
  position: relative;
  color: #00b200;
  border-radius: 5px;
}
.site-sidebar .nav-item.active:after,
.site-sidebar .nav-item.dropdown.show:after,
.site-sidebar .nav-item:hover:after,
.site-sidebar .nav-item .nav-link.active:after {
  content: "";
  position: absolute;
  top: 0;
  left: -1rem;
  display: block;
  width: 5px;
  height: 2.75rem;
  // background: #00b200;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.site-sidebar.open .nav-item.active:after,
.site-sidebar.open .nav-item.dropdown.show:after,
.site-sidebar.open .nav-item:hover:after,
.site-sidebar .nav-item .nav-link.active:after {
  left: -20px;
}
.site-navigation .nav-item.active,
.site-navigation .nav-item.dropdown.show,
.site-navigation .nav-item:hover {
  // background: #00b200;
  background: #ffffff;
}

.site-sidebar .nav > .nav-item .nav-link {
  padding: 10px;
  color: #ffffff;
  display: flex;
flex-direction: row;
align-items: center;
}
.site-navigation .nav-item.active .nav-link,
.site-navigation .nav-item.dropdown.show .nav-link,
.site-navigation .nav-item:hover .nav-link,
.site-navigation .dropdown-menu .nav-item {
  // color: #ffffff;
  color: rgb(12,123,169);
}

.site-navigation .nav-item.dropdown.show .nav-item:hover,
.site-navigation .nav-item.dropdown.show .nav-item.active{
  background-color: #12b8ce;
}

.site-navigation .nav-item.dropdown.show .nav-item:hover .nav-link,
.site-navigation .nav-item.dropdown.show .nav-item.active .nav-link{
  color: #ffffff;
}

.site-sidebar .site-navigation .navbar-text {
  display: none;
}
.site-sidebar.open .site-navigation .navbar-text {
  display: inline-block;
}
.site-sidebar .nav-link .material-icons {
  margin-right: 0;
}
.site-sidebar.open .nav-link .material-icons {
  margin-right: 10px;
}
.site-sidebar .nav-link:after {
  display: none;
}
.site-sidebar.open .nav-link:after {
  display: inline-block;
}
.site-sidebar .nav > .nav-item {
  margin: 0 auto 0.25rem;
}
.site-sidebar.open .nav > .nav-item {
  margin: 0 20px 0.25rem;
}
.site-sidebar:not(.open) .show.dropdown .dropdown-menu {
  display: none !important;
}


/**
   *  # Main
   */
.site-main{
  padding: 1em;
  background-color: #efefef;
}


/**
   *  # Header
   */
.site-header {
  margin-bottom: 1em;
}

/**
   *  # Content
   */
.site-content {
  height: calc(100vh - (25px * 2) - 4rem);
  padding: 1em;
  overflow-x: hidden;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 1em;
  box-shadow: -3px -1px 10px 2px rgba(0,0,0,0.46) inset;
-webkit-box-shadow: -3px -1px 10px 2px rgba(0,0,0,0.46) inset;
-moz-box-shadow: -3px -1px 10px 2px rgba(0,0,0,0.46) inset;
}
.site-content::-webkit-scrollbar {
  width: 5px;
}
.site-content::-webkit-scrollbar-track {
  background: transparent;
}
.site-content::-webkit-scrollbar-thumb {
  border-radius: 6.25rem;
  background: #dedede;
}
.site-content::-webkit-scrollbar-thumb:hover {
  background: #bbbbbb;
}